<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      Regresar a la visualización de notas
    </v-btn>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h2 class="card-label">
            Solicitud de corrección de notas o niveles de logros
          </h2>
        </div>
        <div class="card-toolbar"></div>
      </div>
      <div class="card-body pt-0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="card-label my-3">
                Información de la evaluación del perfil seleccionado
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="d-flex justify-center">
                <v-col cols="12" sm="10" md="10" lg="8" xl="6">
                  <SubEvaluationCardJustInfo
                    :index="subEvaluation.id"
                    :subEvaluation="subEvaluation"
                    :evaluation="evaluation"
                    :totalWeight="evaluation.totalWeight"
                  ></SubEvaluationCardJustInfo>
                </v-col>
                <v-col>
                  <!-- begin:: Stadistic Information: Group Info -->
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="pa-0">
                      <v-sheet elevation="3" class="mb-3 px-3 py-2 rounded-lg">
                        <v-container>
                          <v-row>
                            <v-col>
                              Visualizando los estudiantes de
                              <span v-if="!isTechnicalGroup">la sección </span>
                              <br />
                              <span class="text-h4 font-weight-medium">{{
                                groupInformation.group
                              }}</span>
                              <br />
                              de
                              <span class="text-h6 font-weight-medium">{{
                                groupInformation.grade
                              }}</span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-sheet>
                    </v-col>
                    <!-- end:: Stadistic Information: Group Info -->
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <h3 class="card-label mt-5 mb-4">
          Formulario de solictud de corrección de notas o niveles de logros
        </h3>

        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              Detalles de la solicitud
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2">
              Seleccionar estudiantes
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 3" step="3">
              Ingresar notas o niveles de logros
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="4">
              Confirmar información
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-container>
                <v-row>
                  <v-col>
                    <div class="text-h5 font-weight-medium mb-5">
                      1. Detalles generales de la solicitud
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <div class="black--text mb-1 flex-column">
                      <form-group
                        name="razón de la solicitud"
                        :validator="$v.request.request_reason_id"
                      >
                        <v-select
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          label="Razón de la solicitud de corrección de notas"
                          placeholder="Razón de la solicitud"
                          :items="requestReasons"
                          item-text="name"
                          item-value="id"
                          required
                          filled
                          rounded
                          dense
                          background-color="grey lighten-4"
                          color="black"
                          v-model="request.request_reason_id"
                        ></v-select>
                      </form-group>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <div class="black--text mb-1 flex-column">
                      <form-group
                        name="justificación"
                        :validator="$v.request.justification"
                      >
                        <v-textarea
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          filled
                          rounded
                          dense
                          background-color="grey lighten-4"
                          color="black"
                          counter
                          label="Justificación"
                          auto-grow
                          rows="3"
                          v-model="request.justification"
                        ></v-textarea>
                      </form-group>
                    </div>
                  </v-col>
                </v-row>
              </v-container>

              <div style="width: 100%;" class="d-flex flex-row justify-end">
                <v-btn color="primary" @click="validateRequestInfo">
                  Siguiente
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-container>
                <v-row>
                  <v-col>
                    <div class="text-h5 font-weight-medium">
                      2. Seleccionar estudiantes
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-data-table
                      v-model="selectedStudentsData"
                      :headers="allStudents.headers"
                      :items="allStudents.data"
                      item-key="uuid"
                      value="uuid"
                      show-select
                      :items-per-page="-1"
                      class="elevation-1"
                      :sort-by="'score'"
                      :sort-desc="true"
                    >
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>

              <div style="width: 100%;" class="d-flex flex-row justify-end">
                <v-btn text @click="step = 1">
                  Regresar
                </v-btn>
                <v-btn
                  class="ml-2"
                  color="primary"
                  @click="validateStudentsHasBeenSelected()"
                >
                  Siguiente
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-container>
                <v-row>
                  <v-col>
                    <div class="text-h5 font-weight-medium">
                      3. Ingresar la nueva nota o nivel de logro
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <v-sheet class="d-none d-sm-flex flex-row align-center">
                      <v-container>
                        <v-row no-gutters>
                          <v-col
                            cols="12"
                            sm="3"
                            class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                          >
                            Código
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                          >
                            Nombre completo
                          </v-col>
                          <v-col
                            cols="12"
                            sm="2"
                            class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                          >
                            {{
                              evaluation.subject_type_id == 1
                                ? "Nivel de logro actual"
                                : "Nota actual"
                            }}
                          </v-col>
                          <v-col
                            cols="12"
                            sm="3"
                            class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                          >
                            {{
                              evaluation.subject_type_id == 1
                                ? "Nivel de logro nuevo"
                                : "Nota nueva"
                            }}
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-sheet>
                    <v-hover
                      v-slot="{ hover }"
                      v-for="(student,
                      index) in selectedStudents.customizedData"
                      :key="student.code"
                    >
                      <v-sheet
                        :elevation="hover ? 5 : 0"
                        class="d-flex flex-column flex-sm-row align-md-center mb-1"
                        :color="`grey lighten-${index % 2 ? 3 : 5}`"
                        rounded="xl"
                      >
                        <v-container class="py-0">
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              sm="3"
                              class="d-flex justify-center align-center text-h4 font-weight-medium pr-sm-3 mt-4 mt-sm-0"
                            >
                              {{ student.code }}
                            </v-col>
                            <v-col
                              cols="12"
                              sm="4"
                              class="d-flex justify-center justify-sm-start align-center text-body-1 text-center text-sm-left font-weight-medium text-uppercase mb-2 mb-sm-0"
                            >
                              <div>
                                {{ student.full_name }}
                              </div>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="2"
                              class="d-flex justify-center align-left pa-0 ma-0"
                            >
                              <span class="text-h5 my-auto">
                                {{ student.showScore }}
                              </span>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="3"
                              class="d-flex justify-center align-left pa-0 ma-0"
                            >
                              <SubEvaluationScoreInput
                                :minimumScore="1.0"
                                :maximumScore="maximumScoreAllowed"
                                :code="student.code"
                                :score="student.new_score"
                                @update-score="updateStudentScore"
                              >
                              </SubEvaluationScoreInput>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-sheet>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-container>

              <div style="width: 100%;" class="d-flex flex-row justify-end">
                <v-btn text @click="step = 2">
                  Regresar
                </v-btn>
                <v-btn
                  class="ml-2"
                  color="primary"
                  @click="validateScoresEntered()"
                >
                  Siguiente
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-container>
                <v-row>
                  <v-col>
                    <div class="text-h5 font-weight-medium">
                      4. Verificación de la información ingresada
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex flex-row flex-nowrap">
                    <div class="text-h6 font-weight-medium">
                      Información general
                    </div>
                    <v-divider class="ml-2"></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-left" tag="strong" cols="12">
                    Razón de la solicitud de correción de notas seleccionado
                  </v-col>
                  <v-col class="pt-0">
                    <!-- TODO: Cambiar por el label de la reason -->
                    {{ getRequestReasonLabel }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-left" tag="strong" cols="12">
                    Justificación de la solicitud de correción de notas
                  </v-col>
                  <v-col class="pt-0">
                    <v-sheet
                      rounded="lg"
                      color="white"
                      elevation="1"
                      max-width="740"
                    >
                      <v-row class="ma-0">
                        <v-col>
                          <p class="ma-0">
                            {{
                              request.justification ||
                                "No se ha especificado ninguna justificación."
                            }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex flex-row flex-nowrap">
                    <div class="text-h6 font-weight-medium">
                      Listado de estudiantes
                    </div>
                    <v-divider class="ml-2"></v-divider>
                  </v-col>
                </v-row>
                <v-row class="mb-5">
                  <v-col class="text-left" cols="12">
                    Estudiantes seleccionados con las respectivas correcciones
                  </v-col>
                  <v-col class="pt-0">
                    <v-sheet class="d-none d-sm-flex flex-row align-center">
                      <v-container>
                        <v-row no-gutters>
                          <v-col
                            cols="12"
                            sm="3"
                            class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                          >
                            Código
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                          >
                            Nombre completo
                          </v-col>
                          <v-col
                            cols="12"
                            sm="3"
                            class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                          >
                            Especialidad
                          </v-col>
                          <v-col
                            cols="12"
                            sm="1"
                            class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                          >
                            {{
                              evaluation.subject_type_id == 1
                                ? "Nivel de logro actual"
                                : "Nota actual"
                            }}
                          </v-col>
                          <v-col
                            cols="12"
                            sm="1"
                            class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                          >
                            {{
                              evaluation.subject_type_id == 1
                                ? "Nivel de logro nuevo"
                                : "Nota nueva"
                            }}
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-sheet>
                    <v-hover
                      v-slot="{ hover }"
                      v-for="(student,
                      index) in selectedStudents.customizedData"
                      :key="student.code"
                    >
                      <v-sheet
                        :elevation="hover ? 5 : 0"
                        class="d-flex flex-column flex-sm-row align-md-center mb-1"
                        :color="`grey lighten-${index % 2 ? 3 : 5}`"
                        rounded="xl"
                      >
                        <v-container class="py-0">
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              sm="3"
                              class="d-flex justify-center align-center text-h4 font-weight-medium pr-sm-3 mt-4 mt-sm-0"
                            >
                              {{ student.code }}
                            </v-col>
                            <v-col
                              cols="12"
                              sm="4"
                              class="d-flex justify-center justify-sm-start align-center text-body-1 text-center text-sm-left font-weight-medium text-uppercase mb-2 mb-sm-0"
                            >
                              <div>
                                {{ student.full_name }}
                              </div>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="3"
                              class="d-flex justify-center align-center pa-0 ma-0"
                            >
                              {{ student.technical_group }}
                            </v-col>
                            <v-col
                              cols="12"
                              sm="1"
                              class="d-flex justify-center align-left pa-0 ma-0"
                            >
                              <span class="text-h5 my-auto">
                                {{ student.showScore }}
                              </span>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="1"
                              class="d-flex justify-center align-left pa-0 ma-0"
                            >
                              <v-chip
                                color="blue darken-1"
                                dark
                                class="text-h5 font-weight-medium my-3"
                              >
                                {{ student.new_score }}
                              </v-chip>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-sheet>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-container>

              <div style="width: 100%;" class="d-flex flex-row justify-end">
                <v-btn text @click="step = 3">
                  Regresar
                </v-btn>
                <v-btn class="ml-2" color="primary" :loading="isLoading" :disabled="isBlocked" @click="submit()">
                  Enviar
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <!-- end::Modals -->
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import SubEvaluationCardJustInfo from "@/components/elements/evaluation/SubEvaluationCardJustInfo.vue";
import SubEvaluationScoreInput from "@/components/elements/scores_amend_form/SubEvaluationScoreInput.vue";
import studentRepository from "@/repositories/studentRepository";
import requestReasonRepository from "@/repositories/requestReasonRepository";
import requestRepository from "@/repositories/requestRepository";
import Swal from "sweetalert2";

export default {
  name: "ScoresAmendForm",
  title: "Solicitud de corrección | GE ITR",
  components: {
    SubEvaluationCardJustInfo,
    SubEvaluationScoreInput,
  },
  data() {
    return {
      request: {},
      allStudents: {
        data: [],
        headers: [
          { text: "Código", value: "code" },
          { text: "Nombre completo", value: "full_name" },
          { text: "Especialidad", value: "technical_group" },
          { text: "Nota o nivel de logro", value: "showScore" },
        ],
      },
      selectedStudents: {
        headers: [
          { text: "Código", value: "code" },
          { text: "Nombre completo", value: "full_name" },
          { text: "Especialidad", value: "technical_group" },
          { text: "Nota o nivel de logro actual", value: "current_score" },
          { text: "Nueva nota o nivel de logro", value: "new_score" },
        ],
        customizedData: [],
      },
      selectedStudentsData: [],
      evaluation: {},
      subEvaluation: {},
      groupInformation: {},
      requestReasons: [],
      step: 1,
      REQUEST_CLASIFICATION_ID: 1,
      isLoading: false,
      isBlocked: false,
    };
  },
  props: {
    // f de from para identificar desde que tipo de grupo viene, se acepta "t-g" o "a-g"
    f: {
      type: String,
    },
    // g para el identificador del grupo independiente a que si es técnico o académico
    g: {
      type: String,
    },
    // S_e(subEvaluation) para el identificador de la evaluación
    s_e: {
      type: String,
    },
    // s para la sección en dado caso sea grupo técnico
    s: {
      type: String,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mis asignaturas", route: "teacher_subjects" },
      { title: "Perfiles de la asignatura", route: "subject_evaluations" },
      { title: "Solicitud de corrección", route: "scores_amend_form" },
    ]);
    this.loadStudents();
    this.getRequestReasons();
    this.$store.dispatch(
      GET_CURRENT_PAGE_ACTIONS,
      "Solictud de correción de notas o niveles de logros"
    );
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    checkScore(score) {
      if (Number(score) === 0) {
        return "NPI";
      }
      return score;
    },
    loadStudents() {
      let fetchFn, payload;
      if (this.f == "a-g") {
        fetchFn = studentRepository.getStudentsScoresByAcademicGroup;
        payload = {
          academic_group_id: this.g,
          sub_evaluation_id: this.s_e,
        };
      } else if (this.f == "t-g") {
        fetchFn = studentRepository.getStudentsScoresByTechnicalGroup;
        payload = {
          technical_group_id: this.g,
          sub_evaluation_id: this.s_e,
          section: this.s,
        };
      }

      fetchFn(payload)
        .then(({ data }) => {
          const scoreStudentFixed = data.students.map(info => {
            if (Number(info.score) === 0) {
              info.showScore = "NPI";
            } else {
              info.showScore = info.score;
            }
            return info;
          })
          this.allStudents.data = scoreStudentFixed;
          this.evaluation = data.sub_evaluation.evaluation;
          this.subEvaluation = data.sub_evaluation;
          this.groupInformation = data.group_information;
        })
        .catch((err) => {
          console.error(err);
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    updateStudentScore({ code, score }) {
      const studentData = this.selectedStudents.customizedData.find(
        (item) => item.code == code
      );
      const index = this.selectedStudents.customizedData.indexOf(studentData);

      if (index != -1) {
        this.selectedStudents.customizedData[index].new_score = score;
      }
    },
    validateScoresEntered() {
      if (this.hasEnteredValidScores) {
        this.step = 4;
      } else {
        this.fireToast({
          icon: "error",
          title:
            "Asegurate de haber ingresado las notas o niveles de logros correctamente",
        });
      }
    },
    validateRequestInfo() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.step = 2;
    },
    validateStudentsHasBeenSelected() {
      if (this.selectedStudentsData.length > 0) {
        this.step = 3;
      } else {
        this.fireToast({
          icon: "error",
          title: "Asegurate de haber seleccionado por lo menos un estudiante",
        });
      }
    },
    submit() {
      this.isLoading = true;
      const studentsBody = this.selectedStudents.customizedData.map(item => {
        const { showScore, ...rest } = item;
        return { ...rest };
      })
      const payload = {
        ...this.request,
        sub_evaluation_id: this.subEvaluation.id,
        user_id: this.currentUserPersonalInfo.id_user,
        score_students: studentsBody,
      };
      requestRepository
        .createRequest(payload)
        .then(({ data }) => {
          // If operation has been completed
          if (data.status) {
            this.fireToast({
              title: data.message,
              icon: "success",
            })
            this.$router.go(-1);
            this.isBlocked = true;
          } else {
            // Showing alert with response data
            this.sweetAlertResponse(data);
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible enviar la solicitud 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getRequestReasons() {
      requestReasonRepository
        .getRequestReasonsByRequestClasificationId(
          this.REQUEST_CLASIFICATION_ID
        )
        .then(({ data }) => {
          this.requestReasons = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
  },
  computed: {
    ...mapGetters(["currentPageActions", "currentUserPersonalInfo"]),
    minimumScoreAllowed() {
      const scoreAllowed = this.evaluation.minimum_score || 1.0;
      const parsedScoreAllowed = parseFloat(scoreAllowed);
      return parsedScoreAllowed;
    },
    maximumScoreAllowed() {
      const scoreAllowed = this.evaluation.maximum_score || 10.0;
      const parsedScoreAllowed = parseFloat(scoreAllowed);
      return parsedScoreAllowed;
    },
    hasEnteredValidScores() {
      return this.selectedStudents.customizedData.every((studentData) => {
        const { new_score: score } = studentData;
        let scoreParsed = parseFloat(score);
        return scoreParsed >= 1.0 && scoreParsed <= this.maximumScoreAllowed;
      });
    },
    getRequestReasonLabel() {
      const requestReason = this.requestReasons.find(
        (requestReason) => requestReason.id == this.request.request_reason_id
      );
      return requestReason ? requestReason.name : "Ninguna";
    },
    isTechnicalGroup() {
      return this.f == "t-g";
    },
  },
  watch: {
    selectedStudentsData(newValue, oldValue) {
      this.selectedStudents.customizedData = [];

      const selectedStudentsFormatted = newValue.map((element) => {
        let { code, uuid, full_name, technical_group, score, showScore } = element;        
        return {
          code,
          uuid,
          full_name,
          technical_group,
          current_score: score,
          new_score: "",
          showScore
        };
      });

      this.selectedStudents.customizedData = selectedStudentsFormatted;
    },
  },
  validations: {
    request: {
      request_reason_id: {
        required,
      },
      justification: {
        required,
      },
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
