<template>
  <div class="d-flex flex-row">
    <form-group name="Nota" :validator="$v.newScore" :messages="errorMessages">
      <v-text-field
        slot-scope="{ attrs }"
        v-bind="attrs"
        :background-color="
          score >= 1.0 ? 'light-blue lighten-5' : 'grey lighten-2'
        "
        color="red"
        dense
        placeholder="NPI"
        filled
        rounded
        class="mt-5 text--black text-h5 font-weight-medium"
        style="max-width: 145px;"
        type="number"
        elevation="1"
        min="0"
        step="0.1"
        v-model.trim="$v.newScore.$model"
        @change="formatScore()"
      >
      </v-text-field>
    </form-group>
  </div>
</template>

<script>
import { required, minValue, maxValue } from "vuelidate/lib/validators";

export default {
  name: "SubEvaluationScoreInput",
  data() {
    return {
      errorMessages: {
        minValue: "Nota mínima es {min}",
        maxValue: "Nota máxima es {max}",
      },
      isLoading: false,
      canBeSubmitted: false,
      wasSaved: false,
      newScore: "",
    };
  },
  props: {
    minimumScore: {
      type: Number,
    },
    maximumScore: {
      type: Number,
    },
    score: {
      type: [String, Number],
    },
    code: {
      type: String,
    },
  },
  methods: {
    formatScore() {
      this.newScore = parseFloat(this.newScore).toFixed(1);
      this.$emit("update-score", {
        code: this.code,
        score: this.newScore,
      });
    },
  },
  validations() {
    return {
      newScore: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(this.maximumScore),
      },
    };
  },
};
</script>
